import '@babel/polyfill';

import 'lazysizes';


jQuery = $ = global.jQuery;

$( document ).ready(function() {
  $('#contact-text h2').on('click', function() {
    $(this).parent().find('address').toggleClass('is-open');
  });
  $('#contact-menu a').on('click', function(e) {
    $('body').addClass('mc-' + $(this).closest('li').index());
  });
});
